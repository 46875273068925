@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {

    --background: white;
    --foreground: black;

    --primary: white;
    --primary-foreground: black;

    --secondary: #a3b5c0;
    --secondary-foreground: #1C1C1C;

    --tertiary: #9297A5;

    --card-primary: #14212D;
    --card-secondary: #FFFFFF33;
    --card-tertiary :#F8FAFD;

    
    --muted: #EAF1FB;
    --muted-foreground: #8B929E;

    --popover: #FFFFFF;
    --popover-foreground: #1C1C1C;

    --border: #DAE0E8;
    --input: #DAE0E8;

    --accent: #EAF1FB;
    --accent-foreground: #1C1C1C;

    --destructive: #FF0000;
    --destructive-foreground: #F8FBFE;

    --ring: #9297A5;

    --radius: 0.5rem;
}

.dark {

  --background: #292a2d;
  --foreground: white;

  --primary: black;
  --primary-foreground: white;

  --secondary: #686D76;
  --secondary-foreground: #F8FBFE;

  --tertiary: #c5c8d3;

  --card-primary: #171717;
  --card-secondary: #BCCCDC;
  --card-tertiary :#393E46;

 
  --muted: #393c44;
  --muted-foreground: #99A1B2;

  --accent: #19202A;
  --accent-foreground: #F8FBFE;

  --popover: #030A10;
  --popover-foreground: #667085;

  --border: #4A4947;
  --input: #19202A;

  --destructive: #591919;
  --destructive-foreground: #F8FBFE;

  --ring: #19202A;

  --radius: 0.5rem;
}

}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}